<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadPlantContracts(true)">
                <el-radio-button :label="1">新起草</el-radio-button>
                <el-radio-button :label="100">已退回</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantContracts(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showPlantContractDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="plantContracts.data" class="mt-2">
                <el-table-column prop="year" label="年份" sortable width="80" />
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="farmerName" label="种植户" sortable width="160" />
                <el-table-column prop="warrantorNames" label="担保人" sortable width="160" />
                <el-table-column prop="totalArea" label="总面积（亩）" sortable width="160" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template #default="scope">
                        <el-button type="text" @click="submitPlantContract(scope.row.id)">提交</el-button>
                        <el-button type="text" @click="showPlantContractDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deletePlantContract(scope.row.id)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="plantContractDialogVisible" title="种植合同" :close-on-click-modal="false" width="960px"
            top="0">
            <div style="margin: 0 -16px;">
                <el-form>
                    <div class="form-group-title">基本信息</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="年份" class="form-item-required">
                                    <el-input-number v-model="plantContract.year" :min="1000" :max="9999"
                                        controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="编号" class="form-item-required">
                                    <el-input v-model="plantContract.code" placeholder="编号" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="名称" class="form-item-required">
                                    <el-input v-model="plantContract.name" placeholder="名称" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="流出方式" class="form-item-required">
                                    <el-select v-model="plantContract.outflowMethod" placeholder="流出方式">
                                        <el-option
                                            v-for="plantContractOutflowMethod in $store.state.dataDefinitions.plantContractOutflowMethods"
                                            :key="plantContractOutflowMethod.key"
                                            :value="plantContractOutflowMethod.key"
                                            :label="plantContractOutflowMethod.value" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="土地行政区划" class="form-item-required">
                                    <el-input :value="plantContract.regionName" readonly placeholder="土地行政区划">
                                        <template #append>
                                            <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="承包开始日期" class="form-item-required">
                                    <el-date-picker v-model="plantContract.beginContractDate" placeholder="承包开始日期" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="承包结束日期" class="form-item-required">
                                    <el-date-picker v-model="plantContract.endContractDate" placeholder="承包结束日期" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">水发公司（甲方）</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="24">
                                <el-form-item label="水发公司（甲方）" class="form-item-required">
                                    <el-select v-model="plantContract.companyId" disabled placeholder="水发公司（甲方）">
                                        <el-option v-for="company in companies" :key="company.id" :value="company.id"
                                            :label="company.fullName" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="水发合同签署人姓名" class="form-item-required">
                                    <el-input v-model="plantContract.companySignerName" placeholder="水发合同签署人姓名" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="水发合同签署人手机" class="form-item-required">
                                    <el-input v-model="plantContract.companySignerMobile" placeholder="水发合同签署人手机" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="text-info">
                            注：已注册
                            <a href="https://www.qiyuesuo.com/" target="_blank">契约锁</a>
                            的网签公司，签署人必须是网签公司内部员工。
                        </div>
                    </div>

                    <div class="form-group-title">种植户（乙方）</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="24">
                                <el-form-item label="种植户（乙方）" class="form-item-required">
                                    <el-input :value="plantContract.farmerName" readonly placeholder="种植户（乙方）">
                                        <template #append>
                                            <el-button @click="selectFarmerDialogVisible=true">选择</el-button>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="种植户合同签署人姓名" class="form-item-required">
                                    <el-input v-model="plantContract.farmerSignerName" placeholder="种植户合同签署人姓名" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="种植户合同签署人手机" class="form-item-required">
                                    <el-input v-model="plantContract.farmerSignerMobile" placeholder="种植户合同签署人手机" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="text-info">
                            注：已注册
                            <a href="https://www.qiyuesuo.com/" target="_blank">契约锁</a>
                            的网签公司，签署人必须是网签公司内部员工。
                        </div>
                    </div>

                    <div class="form-group-title">担保人</div>
                    <div class="form-group">
                        <div class="border rounded mt-2 p-1">
                            <div v-for="warrantor in plantContract.warrantors" :key="warrantor.id"
                                class="row no-gutters border rounded mb-1">
                                <div class="col p-3">
                                    {{warrantor.receiverName}}
                                    <span class="text-info">{{warrantor.receiverMobile}}</span>
                                </div>
                                <div class="col-auto">
                                    <el-button type="text" class="text-danger p-3" @click="removeWarrantor(warrantor)">
                                        移除
                                    </el-button>
                                </div>
                            </div>
                            <div>
                                <el-button :disabled="!plantContract.farmerId"
                                    @click="selectWarrantorDialogVisible=true">
                                    添加
                                </el-button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-title">其他签署人</div>
                    <div class="form-group">
                        <div class="border rounded p-1">
                            <div v-for="otherSignatory in plantContract.otherSignatories"
                                :key="otherSignatory.receiverMobile" class="row no-gutters border rounded mb-1">
                                <div class="col p-3">
                                    <div>
                                        {{otherSignatory.receiverName}}
                                        <span class="text-info">{{otherSignatory.receiverMobile}}</span>
                                    </div>
                                    <div v-if="otherSignatory.companyName" class="text-warning">
                                        {{otherSignatory.companyName}}
                                    </div>
                                    <div v-else class="text-info">个人</div>
                                </div>
                                <div class="col-auto">
                                    <el-button type="text" class="text-danger p-3"
                                        @click="removeOtherSignatory(otherSignatory)">
                                        移除
                                    </el-button>
                                </div>
                            </div>
                            <div>
                                <el-button @click="showAddOtherSignatoryDialog">添加</el-button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-title">内容</div>
                    <div class="form-group">
                        <div>
                            <div>签署的土地（{{plantContractPlantContractLandsTotalArea}} 亩）</div>
                            <div class="border rounded mt-2 p-1">
                                <div v-for="(plantContractLand,index) in plantContract.plantContractLands" :key="index"
                                    class="row no-gutters border rounded mb-1">
                                    <div class="col p-3">
                                        <div>
                                            <router-link :to="'/land/detail?id='+plantContractLand.landId"
                                                target="_blank">
                                                {{plantContractLand.landName}}
                                            </router-link>
                                            <span class="text-info">{{plantContractLand.landArea}} 亩</span>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <el-button type="text" class="text-danger p-3"
                                            @click="removeLand(plantContractLand)">
                                            移除
                                        </el-button>
                                    </div>
                                </div>
                                <div>
                                    <el-button @click="selectLandDialogVisible=true">添加</el-button>
                                </div>
                            </div>
                        </div>

                        <el-row :gutter="8" class="mt-2">
                            <el-col :span="6">
                                <el-form-item label="总面积（亩）" class="form-item-required">
                                    <el-input-number v-model="plantContract.totalArea" controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="总金额（元）" class="form-item-required">
                                    <el-input-number v-model="plantContract.totalAmount" controls-position="right"
                                        :min="0" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="种植积分单价（元/亩）" class="form-item-required">
                                    <el-input-number v-model="plantContract.plantPointUnitPrice"
                                        controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="总种植积分（元）" class="form-item-required">
                                    <el-input :value="plantContractTotalPlantPoint" disabled />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">附件</div>
                    <div class="form-group">
                        <div>
                            <div>待签约合同附件</div>
                            <div class="border rounded mt-2 p-1">
                                <multi-file-uploader v-model="plantContract.draftContractAttachments"
                                    accept=".doc,.docx,.pdf,.jpg,.jpeg,.png" />
                            </div>
                        </div>

                        <div class="mt-3">
                            <div>其他附件</div>
                            <div class="border rounded mt-2 p-1">
                                <multi-file-uploader v-model="plantContract.otherAttachments" />
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <span v-if="plantContract.totalArea!=plantContractPlantContractLandsTotalArea"
                    class="text-danger mr-3">总面积与土地签署面积不相符</span>
                <el-button type="primary" @click="savePlantContract">确定</el-button>
                <el-button @click="plantContractDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="addOtherSignatoryDialogVisible" title="其他签署人" :close-on-click-modal="false"
            width="560px">
            <el-form>
                <el-form-item>
                    <el-radio-group v-model="otherSignatory.isCompany" class="stretch-radio-group"
                        @change="otherSignatory.companyName=''">
                        <el-radio-button :label="false">个人</el-radio-button>
                        <el-radio-button :label="true">公司</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="otherSignatory.isCompany" label="公司名称" class="form-item-required">
                    <el-input v-model="otherSignatory.companyName" placeholder="公司名称" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="签署人姓名" class="form-item-required">
                            <el-input v-model="otherSignatory.receiverName" placeholder="签署人姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="签署人电话" class="form-item-required">
                            <el-input v-model="otherSignatory.receiverMobile" placeholder="签署人电话" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="addOtherSignatory">确定</el-button>
                <el-button @click="addOtherSignatoryDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="plantContract.regionId=$event.id;plantContract.regionName=$event.name" />

        <select-farmer-dialog v-model="selectFarmerDialogVisible"
            @select="plantContract.farmerId=$event.id;plantContract.farmerName=$event.registeredUser.name;plantContract.farmerSignerName=$event.contactName;plantContract.farmerSignerMobile=$event.contactPhone" />

        <select-warrantor-dialog v-model="selectWarrantorDialogVisible" :farmer-id="plantContract.farmerId"
            @select="addWarrantor" />

        <select-not-signed-land-dialog v-model="selectLandDialogVisible" @select="addLand" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: 1,
                key: null,
                companies: [],
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantContract: {},
                otherSignatory: {},
                plantContractDialogVisible: false,
                selectRegionDialogVisible: false,
                selectFarmerDialogVisible: false,
                selectWarrantorDialogVisible: false,
                addOtherSignatoryDialogVisible: false,
                selectLandDialogVisible: false,
            };
        },
        computed: {
            plantContractTotalPlantPoint() {
                return this.plantContract.totalArea * this.plantContract.plantPointUnitPrice;
            },
            plantContractPlantContractLandsTotalArea() {
                if (!this.plantContract.plantContractLands) {
                    return 0;
                }
                let total = 0;
                for (const plantContractLand of this.plantContract.plantContractLands) {
                    total += plantContractLand.landArea;
                }
                return total;
            },
        },
        methods: {
            async loadCompanies() {
                let response = await this.$axios.get('/api/Organization/GetCompanies');
                this.companies = response.data;
            },
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: this.status,
                        key: this.key,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            showPlantContractDialog(plantContract) {
                if (plantContract) {
                    this.plantContract = JSON.parse(JSON.stringify(plantContract));
                }
                else {
                    let company = this.$store.state.companies.filter(p => p.id == this.$store.state.companyId)[0];

                    this.plantContract = {
                        year: new Date().getFullYear(),
                        companyId: this.$store.state.companyId,
                        companySignerName: company.companySignerName,
                        companySignerMobile: company.companySignerMobile,

                        farmerSignerName: '',
                        farmerSignerMobile: '',

                        warrantors: [],
                        otherSignatories: [],

                        plantContractLands: [],
                        totalArea: 0,
                        totalAmount: 0,
                        plantPointUnitPrice: 600,

                        draftContractAttachments: [],
                        otherAttachments: [],
                    };
                }
                this.plantContractDialogVisible = true;
            },
            addWarrantor(warrantor) {
                this.plantContract.warrantors.push({
                    userId: warrantor.id,
                    receiverName: warrantor.registeredUser.name,
                    receiverMobile: warrantor.registeredUser.phone,
                });
            },
            removeWarrantor(warrantor) {
                let index = this.plantContract.warrantors.indexOf(warrantor);
                this.plantContract.warrantors.splice(index, 1);
            },
            showAddOtherSignatoryDialog() {
                this.otherSignatory = { isCompany: false, companyName: '' };
                this.addOtherSignatoryDialogVisible = true;
            },
            addOtherSignatory() {
                this.plantContract.otherSignatories.push(this.otherSignatory);
                this.addOtherSignatoryDialogVisible = false;
            },
            removeOtherSignatory(otherSignatory) {
                let index = this.plantContract.otherSignatories.indexOf(otherSignatory);
                this.plantContract.otherSignatories.splice(index, 1);
            },
            addLand(land) {
                this.plantContract.plantContractLands.push({
                    landId: land.id,
                    landName: land.name,
                    landArea: land.area,
                });
            },
            removeLand(land) {
                let index = this.plantContract.plantContractLands.indexOf(land);
                this.plantContract.plantContractLands.splice(index, 1);
            },
            async savePlantContract() {
                if (this.plantContract.id == undefined) {
                    await this.$axios.post('/api/PlantContract/AddPlantContract', {
                        year: this.plantContract.year,
                        code: this.plantContract.code,
                        name: this.plantContract.name,
                        outflowMethod: this.plantContract.outflowMethod,
                        regionId: this.plantContract.regionId,
                        beginContractDate: this.$moment(this.plantContract.beginContractDate).format('YYYY/M/D'),
                        endContractDate: this.$moment(this.plantContract.endContractDate).format('YYYY/M/D'),

                        companyId: this.plantContract.companyId,
                        companySignerName: this.plantContract.companySignerName,
                        companySignerMobile: this.plantContract.companySignerMobile,

                        farmerId: this.plantContract.farmerId,
                        farmerSignerName: this.plantContract.farmerSignerName,
                        farmerSignerMobile: this.plantContract.farmerSignerMobile,

                        warrantors: this.plantContract.warrantors,
                        otherSignatories: this.plantContract.otherSignatories,

                        landIds: this.plantContract.plantContractLands.map(p => p.landId),
                        totalArea: this.plantContract.totalArea,
                        totalAmount: this.plantContract.totalAmount,
                        plantPointUnitPrice: this.plantContract.plantPointUnitPrice,

                        draftContractAttachments: this.plantContract.draftContractAttachments,
                        otherAttachments: this.plantContract.otherAttachments,
                    });
                    this.plantContractDialogVisible = false;
                    this.loadPlantContracts();
                }
                else {
                    await this.$axios.post('/api/PlantContract/UpdatePlantContract', {
                        id: this.plantContract.id,
                        year: this.plantContract.year,
                        code: this.plantContract.code,
                        name: this.plantContract.name,
                        outflowMethod: this.plantContract.outflowMethod,
                        regionId: this.plantContract.regionId,
                        beginContractDate: this.$moment(this.plantContract.beginContractDate).format('YYYY/M/D'),
                        endContractDate: this.$moment(this.plantContract.endContractDate).format('YYYY/M/D'),

                        companyId: this.plantContract.companyId,
                        companySignerName: this.plantContract.companySignerName,
                        companySignerMobile: this.plantContract.companySignerMobile,

                        farmerId: this.plantContract.farmerId,
                        farmerSignerName: this.plantContract.farmerSignerName,
                        farmerSignerMobile: this.plantContract.farmerSignerMobile,

                        warrantors: this.plantContract.warrantors,
                        otherSignatories: this.plantContract.otherSignatories,

                        landIds: this.plantContract.plantContractLands.map(p => p.landId),
                        totalArea: this.plantContract.totalArea,
                        totalAmount: this.plantContract.totalAmount,
                        plantPointUnitPrice: this.plantContract.plantPointUnitPrice,

                        draftContractAttachments: this.plantContract.draftContractAttachments,
                        otherAttachments: this.plantContract.otherAttachments,
                    });
                    this.plantContractDialogVisible = false;
                    this.loadPlantContracts();
                }
            },
            async deletePlantContract(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/DeletePlantContract', { id: id });
                this.loadPlantContracts();
            },
            async submitPlantContract(id) {
                if (!confirm('确定要提交吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/SubmitPlantContract', { id: id });
                this.loadPlantContracts();
            },
        },
        created() {
            this.loadCompanies();
            this.loadPlantContracts();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>